/*!
 * Project: 'pcmall';
 * Module: 'voucher';
 * Tag: 'pc-v5.15.0';
 * Branch: 'heads/pc-v5.15.0';
 * Last commit: 'chore(release): pc-v5.15.0' (0d6fac332d847f805373ab31341b4088e62cec7d);
 * Updated: '2022-07-21T02:35:49.626Z';
 */
.vbFQcW {
    color: rgba(0, 0, 0, .87)
}

.Vw34x1 {
    display: flex
}

.pJuGpo {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.pJuGpo:not(:first-child) {
    padding-left: .25rem
}

.IB06sp {
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2
}

.kvLrAc {
    font-size: 1rem;
    line-height: 1.25rem
}

.a2Otu-,
.Kbpq57 {
    font-size: .875rem;
    line-height: 1rem
}

.Nkj9tw {
    font-size: .75rem;
    line-height: .875rem
}

.PdY0Vy {
    color: rgba(0, 0, 0, .87)
}

.FmGQB8 {
    display: inline
}

.qVRE21 {
    display: inline-flex;
    vertical-align: middle;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.EA9lr5,
.qVRE21 {
    justify-content: center;
    align-items: center
}

.EA9lr5 {
    color: #fff;
    border-radius: .125rem;
    padding: .0625rem .25rem;
    font-size: .625rem;
    line-height: .75rem;
    font-weight: 300;
    display: flex;
    word-break: keep-all;
    white-space: nowrap
}

.yv68K7 {
    background: #d0021b
}

._5OQgNa {
    background: #ee4d2d
}

.EoB1KW {
    display: flex;
    align-items: center
}

.YTBxir {
    height: .875rem;
    width: 3.75rem;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat
}

.EB8SEw {
    display: inline-block;
    vertical-align: top
}

.oDOFV5 {
    display: flex;
    align-items: center
}

.MCYRBF {
    height: .875rem;
    width: 3.75rem;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat
}

.Ye-hND {
    font-size: .75rem;
    line-height: .875rem;
    color: rgba(0, 0, 0, .54)
}

.mpTlYm,
.Ye-hND {
    margin-top: .25rem
}

.mpTlYm {
    display: flex;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal
}

.yNIyxP {
    color: #ee4d2d
}

.rSFiRJ {
    color: rgba(0, 0, 0, .54)
}

.jjBnhm {
    color: #ff424f
}

.PYtqRd {
    color: #ee4d2d
}

.X4QhKP :first-letter {
    text-transform: capitalize
}

.wAr1RU {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.m9r1QO {
    margin: .3125rem .3125rem 0 0
}

._6sYeVR {
    color: #ff424f;
    font-weight: 500
}

._3pkiG8 {
    display: flex;
    align-items: center
}

._3pkiG8 ._97hzML {
    margin-right: .25rem
}

.h2NkTL {
    width: 100%;
    height: .25rem;
    background: rgba(0, 0, 0, .09);
    border-radius: .25rem;
    overflow: hidden
}

.stardust-icon {
    stroke: currentColor;
    fill: currentColor;
    width: 1em;
    height: 1em
}

.uUaTlG {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1.875rem
}

.YJoQY3 {
    padding: 1.3125rem
}

.zWMU84 {
    padding: 1.875rem
}

.zvg9y0 {
    width: 3.5rem;
    height: 3.5rem;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 50%;
    overflow: hidden
}

.zvg9y0 svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 50%;
    width: 50%
}

.T7oXeu {
    position: relative
}

.T7oXeu img {
    vertical-align: bottom
}

.JiTeHL {
    visibility: hidden
}

.Pyi289 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.DvZDC6 {
    opacity: 0
}

._23Ulzv {
    opacity: 1;
    transition: opacity .2s ease
}

.dg\+G5l {
    max-height: 100%;
    max-width: 100%;
    height: 60px;
    width: 60px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54 61' fill='%23e5e4e4'%3E%3Cpath d='M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z'/%3E%3C/svg%3E")
}

.zgPziC ._8YUv29 {
    right: 0;
    bottom: 0
}

._8YUv29,
.zgPziC ._8YUv29 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    z-index: -1
}

._8YUv29 {
    width: 100%;
    height: 100%
}

.wSpkdE {
    font-weight: 500;
    color: rgba(0, 0, 0, .87);
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.25rem;
    max-height: 2.6875rem
}

.VoDVkQ {
    height: 1.25rem
}

.zdmfMG:not(:first-child) {
    padding-left: .25rem
}

.MsidDB {
    max-height: 1.25rem;
    white-space: nowrap
}

._9u48LM,
.MsidDB {
    font-weight: 500;
    color: rgba(0, 0, 0, .87);
    font-size: 1rem;
    line-height: 1.25rem;
    text-overflow: ellipsis;
    overflow: hidden
}

._9u48LM {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 2.6875rem;
    word-break: break-word;
    margin-top: .3125rem
}

._9u48LM.zeX4Ih {
    margin-top: 0;
    font-weight: 400
}

.qGIhCc {
    display: inline
}

._9fZVrf.xFMzbu {
    position: relative;
    display: flex;
    padding: .125rem 1px .25rem;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: .125rem;
    overflow: visible
}

._9fZVrf.rP7HfV {
    box-shadow: .125rem .125rem .3125rem rgba(0, 0, 0, .07);
    padding: 0
}

.UjxUvQ {
    width: .3125rem
}

.jS0sJa,
.UjxUvQ {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%
}

.jS0sJa {
    width: 1px;
    background: repeating-linear-gradient(var(--border-color), var(--border-color) .1875rem, transparent 0, transparent .625rem);
    background-size: 1px .625rem
}

.Q1\+kzn {
    position: absolute;
    top: 0;
    left: 0;
    width: .25rem;
    height: 100%;
    background: radial-gradient(circle at 0, at .375rem, transparent 0, transparent .1875rem, var(--border-color) 0, var(--border-color) .25rem, transparent 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, transparent .1875rem, var(--border-color) 0, var(--border-color) .25rem, transparent 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.Q1\+kzn.rP7HfV {
    background: radial-gradient(circle at 0, at .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, var(--border-color) 0, var(--border-color) .25rem, transparent 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, var(--border-color) 0, var(--border-color) .25rem, transparent 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.kl3UdT .Q1\+kzn {
    background: radial-gradient(circle at 0, at .375rem, transparent 0, transparent .1875rem, #fbc9c0 0, #fbc9c0 .25rem, #fff4f4 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, transparent .1875rem, #fbc9c0 0, #fbc9c0 .25rem, #fff4f4 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.kl3UdT .Q1\+kzn.rP7HfV {
    background: radial-gradient(circle at 0, at .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, #fbc9c0 0, #fbc9c0 .25rem, #fff4f4 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, #fbc9c0 0, #fbc9c0 .25rem, #fff4f4 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.kl3UdT .jS0sJa {
    background: repeating-linear-gradient(#fbc9c0, #fbc9c0 .1875rem, transparent 0, transparent .625rem);
    background-size: 1px .625rem
}

.yAXsq7 .Q1\+kzn {
    background: radial-gradient(circle at 0, at .375rem, transparent 0, transparent .1875rem, #f8d0d3 0, #f8d0d3 .25rem, #fff4f4 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, transparent .1875rem, #f8d0d3 0, #f8d0d3 .25rem, #fff4f4 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.yAXsq7 .Q1\+kzn.rP7HfV {
    background: radial-gradient(circle at 0, at .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, #f8d0d3 0, #f8d0d3 .25rem, #fff4f4 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, #f8d0d3 0, #f8d0d3 .25rem, #fff4f4 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.yAXsq7 .jS0sJa {
    background: repeating-linear-gradient(#f8d0d3, #f8d0d3 .1875rem, transparent 0, transparent .625rem);
    background-size: 1px .625rem
}

.QO8yE\+ .Q1\+kzn {
    background: radial-gradient(circle at 0, at .375rem, transparent 0, transparent .1875rem, rgba(6, 107, 200, .25) 0, rgba(6, 107, 200, .25) .25rem, #f2f9ff 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, transparent .1875rem, rgba(6, 107, 200, .25) 0, rgba(6, 107, 200, .25) .25rem, #f2f9ff 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.QO8yE\+ .Q1\+kzn.rP7HfV {
    background: radial-gradient(circle at 0, at .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, rgba(6, 107, 200, .25) 0, rgba(6, 107, 200, .25) .25rem, #f2f9ff 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, rgba(6, 107, 200, .25) 0, rgba(6, 107, 200, .25) .25rem, #f2f9ff 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.QO8yE\+ .jS0sJa {
    background: repeating-linear-gradient(rgba(6, 107, 200, .25), rgba(6, 107, 200, .25) .1875rem, transparent 0, transparent .625rem);
    background-size: 1px .625rem
}

._9ErSPU {
    position: relative;
    display: flex;
    min-width: .3125rem;
    margin-left: 1px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-radius: .1875rem 0 0 .1875rem
}

._9ErSPU.kl3UdT {
    border-top: 1px solid #fbc9c0;
    border-bottom: 1px solid #fbc9c0
}

._9ErSPU.kl3UdT,
._9ErSPU.yAXsq7 {
    background: linear-gradient(90deg, transparent 0, transparent .25rem, #fff4f4 0);
    background: linear-gradient(90deg, transparent 0 .25rem, #fff4f4 .25rem)
}

._9ErSPU.yAXsq7 {
    border-top: 1px solid #f8d0d3;
    border-bottom: 1px solid #f8d0d3
}

._9ErSPU.QO8yE\+ {
    border-top: 1px solid rgba(6, 107, 200, .25);
    border-bottom: 1px solid rgba(6, 107, 200, .25);
    background: linear-gradient(90deg, transparent 0, transparent .25rem, #f2f9ff 0);
    background: linear-gradient(90deg, transparent 0 .25rem, #f2f9ff .25rem)
}

.SRAiAj {
    bottom: 0;
    left: 0;
    width: calc(100% - .375rem);
    box-shadow: .125rem 0 .1875rem .125rem rgba(0, 0, 0, .05)
}

._9Qf19x,
.SRAiAj {
    position: absolute
}

._9Qf19x {
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background: repeating-linear-gradient(var(--border-color), var(--border-color) .125rem, transparent 0, transparent .25rem);
    background: repeating-linear-gradient(var(--border-color), var(--border-color) .125rem, transparent 0 .25rem);
    background-size: 1px .25rem;
    background-repeat: repeat-y
}

.a0qbW5 {
    position: relative;
    display: flex;
    min-width: 0;
    background: #fff;
    border: 1px solid var(--border-color);
    border-left: 0 solid transparent;
    border-radius: 0 .125rem .125rem 0
}

.a0qbW5.kl3UdT {
    background: #fff4f4;
    border: 1px solid #fbc9c0;
    border-left: 0 solid transparent
}

.a0qbW5.yAXsq7 {
    background: #fff4f4;
    border: 1px solid #f8d0d3;
    border-left: 0 solid transparent
}

.a0qbW5.QO8yE\+ {
    background: #f2f9ff;
    border: 1px solid rgba(6, 107, 200, .25);
    border-left: 0 solid transparent
}

.rUjxLt {
    width: 7.375rem;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, transparent 0, transparent .1875rem, var(--background-color) 0);
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-radius: .125rem 0 0 .125rem
}

.NdpUgy {
    width: 6.625rem
}

.jIFCDN {
    position: absolute;
    top: .3125rem;
    left: 0;
    width: .25rem;
    height: calc(100% - .4375rem);
    background: radial-gradient(circle at 0, at .25rem, transparent 0, transparent .1875rem, var(--border-color) 0, var(--background-color) .25rem);
    background: radial-gradient(circle at 0 .25rem, transparent 0, transparent .1875rem, var(--border-color) 0, var(--background-color) .25rem);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.jIFCDN:before {
    top: -.3125rem;
    height: .3125rem;
    border-radius: .125rem 0 0 0;
    border-top: 1px solid var(--border-color)
}

.jIFCDN:after,
.jIFCDN:before {
    content: "";
    position: absolute;
    width: 100%;
    background: var(--background-color);
    border-left: 1px solid var(--border-color)
}

.jIFCDN:after {
    bottom: -.125rem;
    left: 0;
    height: .125rem;
    border-bottom: 1px solid var(--border-color);
    border-radius: 0 0 0 .125rem
}

.buv384 {
    color: #fff;
    font-size: 1.25rem;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.375rem;
    max-height: 2.75rem;
    text-transform: uppercase;
    text-align: center
}

.dbzfqh {
    font-size: 1rem
}

.M06po5 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 7.375rem;
    height: calc(100% - .125rem);
    position: relative;
    display: flex;
    background: linear-gradient(90deg, transparent 0, transparent .1875rem, #fff .25rem);
    background-origin: border-box;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px dashed #e8e8e8
}

.M06po5.Z3ZoHA {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.M06po5.Z3ZoHA .tNqcUu {
    color: #bbb
}

.K2cJFr {
    width: 6.625rem
}

.J-O-xh {
    top: 0;
    left: 0;
    position: absolute;
    width: .25rem;
    height: 100%
}

.J-O-xh:before {
    top: 0
}

.J-O-xh:after,
.J-O-xh:before {
    content: "";
    position: absolute;
    width: 100%;
    height: .1875rem;
    border-left: 1px solid #e8e8e8;
    background: #fff
}

.J-O-xh:after {
    bottom: 0
}

.YpJcm8 {
    position: absolute;
    top: .1875rem;
    left: 0;
    width: .25rem;
    height: calc(100% - .375rem);
    background: radial-gradient(circle at 0, at .375rem, transparent 0, transparent .125rem, #e8e8e8 .1875rem, transparent .25rem, #fff 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, transparent .125rem, #e8e8e8 .1875rem, transparent .25rem, #fff 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.YpJcm8.y6AwDY {
    background: radial-gradient(circle at 0, at .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, #e8e8e8 0, #e8e8e8 .25rem, #fff 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, #e8e8e8 0, #e8e8e8 .25rem, #fff 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.YpJcm8:before {
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    background: repeating-linear-gradient(#e8e8e8, #e8e8e8 .125rem, transparent 0, transparent .625rem);
    background-size: 1px .625rem
}

._3R1ibn,
.YpJcm8:before {
    position: absolute;
    width: 1px
}

._3R1ibn {
    top: .5625rem;
    bottom: .5625rem;
    right: 0;
    z-index: 2;
    background: transparent;
    border-right: 1px dashed #d3d3d3;
    -webkit-transform: translate(50%);
    transform: translate(50%)
}

.Z3ZoHA .tNqcUu,
.Z3ZoHA .y-yKDp {
    opacity: .5
}

.y-yKDp {
    height: 3.5rem;
    width: 3.5rem;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #efefef
}

.qflFPJ {
    max-height: 70%;
    max-width: 70%
}

.aiHqzm {
    width: 100%;
    height: 100%
}

.tNqcUu {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: .75rem;
    line-height: .875rem;
    max-height: 1.75rem;
    max-width: 90%;
    text-align: center;
    -webkit-box-pack: center;
    word-break: break-word;
    margin-top: .25rem;
    padding: 0 .5rem
}

.N2\+hnT {
    width: 3.5rem;
    height: 3.5rem;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 50%;
    overflow: hidden
}

.N2\+hnT svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 50%;
    width: 50%
}

.ZMiRzy {
    position: relative;
    height: .125rem
}

.ERCHN\+ {
    position: absolute;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.eeVyr6 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 7.375rem;
    height: calc(100% - .125rem);
    position: relative;
    display: flex;
    background: linear-gradient(90deg, transparent 0, transparent .25rem, #fff 0);
    background-origin: border-box;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px dashed #e8e8e8
}

.eeVyr6.eTe12w>* {
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.eeVyr6.eTe12w>* .wBj0MG {
    color: #bbb
}

.uMcd\+J {
    width: 6.625rem
}

.jw7ah8 {
    position: relative;
    width: 7.375rem;
    height: 100%;
    border-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-mask-image: radial-gradient(circle at 0, at .3125rem, transparent 0, transparent .1875rem, #000 0);
    -webkit-mask-image: radial-gradient(circle at 0 .3125rem, transparent 0, transparent .1875rem, #000 0);
    -webkit-mask-position: 0 0;
    -webkit-mask-size: 100% .625rem;
    -webkit-mask-repeat: repeat-y;
    -webkit-mask-mode: match-source;
    background-color: #fafafa
}

.jw7ah8.eTe12w>* {
    -webkit-filter: drop-shadow(-.0625rem 0 .03125rem #e8e8e8) grayscale(1);
    filter: drop-shadow(-.0625rem 0 .03125rem #e8e8e8) grayscale(1)
}

.jw7ah8.eTe12w>* .wBj0MG {
    color: #bbb
}

.Xg\+QKo {
    top: 0;
    left: 0;
    position: absolute;
    width: .25rem;
    height: 100%
}

.Xg\+QKo:before {
    top: 0
}

.Xg\+QKo:after,
.Xg\+QKo:before {
    content: "";
    position: absolute;
    width: 100%;
    height: .1875rem;
    border-left: 1px solid #e8e8e8;
    background: #fff
}

.Xg\+QKo:after {
    bottom: 0
}

.ckZv\+6 {
    position: absolute;
    top: .1875rem;
    left: 0;
    width: .25rem;
    height: calc(100% - .375rem);
    background: radial-gradient(circle at 0, at .375rem, transparent 0, transparent .1875rem, #e8e8e8 0, transparent .25rem, #fff 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, transparent .1875rem, #e8e8e8 0, transparent .25rem, #fff 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.ckZv\+6.SKZWad {
    background: radial-gradient(circle at 0, at .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, #e8e8e8 0, #e8e8e8 .25rem, #fff 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, #e8e8e8 0, #e8e8e8 .25rem, #fff 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.ckZv\+6:before {
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    background: repeating-linear-gradient(#e8e8e8, #e8e8e8 .125rem, transparent 0, transparent .625rem);
    background-size: 1px .625rem
}

._5Nr0f4,
.ckZv\+6:before {
    position: absolute;
    width: 1px
}

._5Nr0f4 {
    top: .5625rem;
    bottom: .5625rem;
    right: 0;
    z-index: 2;
    background: transparent;
    border-right: 1px dashed #d3d3d3;
    -webkit-transform: translate(50%);
    transform: translate(50%)
}

.Xgpbsa {
    height: 3.5rem;
    width: 3.5rem;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #efefef
}

.ovmPFk {
    max-height: 70%;
    max-width: 70%
}

.jwrHIh,
.ovmPFk.nm-Nrr {
    background-color: #fafafa
}

._0G-80y {
    width: 100%;
    height: 100%
}

.wBj0MG {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: .75rem;
    line-height: .875rem;
    max-height: 1.75rem;
    max-width: 90%;
    text-align: center;
    -webkit-box-pack: center;
    word-break: break-word;
    margin-top: .3125rem;
    padding: 0 .5rem
}

.iZAzSq {
    width: 3.5rem;
    height: 3.5rem;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 50%;
    overflow: hidden
}

.iZAzSq svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 50%;
    width: 50%
}

._4Q68bz {
    width: 7.5rem;
    height: 100%
}

.fWD0Nd {
    width: 3.75rem;
    height: 1rem;
    background-color: #fff;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 1.75rem;
    border: .03125rem solid rgba(0, 0, 0, .09);
    box-shadow: 0 0 .375rem rgba(0, 0, 0, .06);
    position: absolute;
    bottom: .5rem;
    background-size: auto .75rem;
    background-position: 50%;
    background-repeat: no-repeat
}

.eTe12w ._0G-80y,
.eTe12w .wBj0MG {
    opacity: .5
}

._8Tpxy8 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 7.375rem;
    height: calc(100% - .125rem);
    position: relative;
    display: flex;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-radius: .125rem 0 0 .125rem;
    background: linear-gradient(90deg, transparent 0, transparent .1875rem, var(--background-color) 0)
}

._3txBzE {
    width: 6.625rem
}

.VkYCXx {
    position: absolute;
    top: .3125rem;
    left: 0;
    width: .25rem;
    height: calc(100% - .4375rem);
    background: radial-gradient(circle at 0, at .25rem, transparent 0, transparent .1875rem, var(--border-color) 0, var(--background-color) .25rem);
    background: radial-gradient(circle at 0 .25rem, transparent 0, transparent .1875rem, var(--border-color) 0, var(--background-color) .25rem);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.VkYCXx:before {
    top: -.375rem;
    height: .375rem;
    border-radius: .125rem 0 0 0;
    border-top: 1px solid var(--border-color)
}

.VkYCXx:after,
.VkYCXx:before {
    content: "";
    position: absolute;
    width: 100%;
    background: var(--background-color);
    border-left: 1px solid var(--border-color)
}

.VkYCXx:after {
    bottom: -.1875rem;
    left: 0;
    height: .1875rem;
    border-bottom: 1px solid var(--border-color);
    border-radius: 0 0 0 .125rem
}

.pyjLIT {
    height: 3.5rem;
    width: 3.5rem;
    overflow: hidden
}

.jyHWDR {
    max-width: 80%;
    max-height: 80%;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1)
}

.Q3TBqA {
    width: 100%;
    height: 100%
}

.CBe6YY {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: .75rem;
    line-height: .875rem;
    max-height: 1.75rem;
    max-width: 90%;
    color: #fff;
    text-align: center;
    -webkit-box-pack: center;
    word-break: break-word;
    margin-top: .3125rem;
    padding: 0 .5rem
}

.BuGqVe {
    text-transform: uppercase
}

.qa9DDd {
    display: flex;
    overflow: hidden;
    border-radius: .125rem;
    position: relative;
    overflow: visible
}

.VK0V4t {
    box-shadow: .125rem .125rem .3125rem rgba(0, 0, 0, .30);
    padding: 0
}

.VK0V4t:hover {
    box-shadow: .125rem .125rem .3125rem rgba(0, 0, 0, .70);
    padding: 0;
    cursor: pointer;
}

/* .btn {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    flex-direction: column;
    font-size: 14px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .09);
    border-radius: 2px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    outline: 0;
    cursor: pointer
} */

.btn--inline {
    display: inline-flex
}

.btn--full {
    width: 100%
}

.btn--s {
    height: 34px;
    padding: 0 15px;
    min-width: 60px;
    max-width: 190px
}

.btn--m {
    height: 40px;
    padding: 0 20px;
    min-width: 70px;
    max-width: 220px
}

.btn--l {
    font-size: 16px;
    height: 48px;
    padding: 0 20px
}

.btn--l,
.btn--xl {
    min-width: 80px;
    max-width: 250px
}

.btn--xl {
    height: 52px
}

.shopee-button-solid {
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4, 0, .6, 1);
    position: relative;
    background: rgba(0, 0, 0, .54);
    color: #fff;
    transition: opacity .2s ease;
    border-radius: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .09)
}

.shopee-button-solid:active {
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.shopee-button-solid--fill {
    height: 100%;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0
}

.shopee-button-solid--complement {
    background: #00bfa5
}

.shopee-button-solid--complement:hover {
    background: #00a08a
}

.shopee-button-solid--secondary {
    background: #3b3f53
}

.shopee-button-solid--primary {
    position: relative;
    overflow: visible;
    outline: 0;
    background: #ee4d2d
}

.shopee-button-solid--primary.focus-visible:before,
.shopee-button-solid--primary:focus-visible:before {
    content: "";
    width: calc(100% + var(--focus-indicator-spacing)*2);
    height: calc(100% + var(--focus-indicator-spacing)*2);
    padding: var(--focus-indicator-spacing);
    outline: var(--focus-indicator-stroke-width) solid var(--focus-indicator-color);
    position: absolute;
    border-radius: var(--focus-indicator-border-radius);

}

.shopee-button-solid--primary:not(.shopee-button-solid--disabled):hover {
    background: #f05d40
}

.shopee-button-solid--primary:not(.shopee-button-solid--disabled):active {
    background: #e2492b;
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.shopee-button-solid--primary.shopee-button-solid--disabled {
    background: #facac0 !important;
    cursor: not-allowed;
    box-shadow: none
}

.shopee-button-solid--tributton {
    padding: 10px 13px !important;
    flex: none !important;
    text-transform: uppercase !important;
    width: 102px;
    height: 36px;
    position: relative;
    overflow: visible;
    outline: 0;
    background: #ee4d2d
}

.shopee-button-solid--tributton.focus-visible:before,
.shopee-button-solid--tributton:focus-visible:before {
    content: "";
    width: calc(100% + var(--focus-indicator-spacing)*2);
    height: calc(100% + var(--focus-indicator-spacing)*2);
    padding: var(--focus-indicator-spacing);
    outline: var(--focus-indicator-stroke-width) solid var(--focus-indicator-color);
    position: absolute;
    border-radius: var(--focus-indicator-border-radius);

}

.shopee-button-solid--tributton:not(.disabled):hover {
    background: #f05d40
}

.shopee-button-solid--tributton:not(.disabled):active {
    background: #e2492b;
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.shopee-button-solid--tributton.disabled {
    background: #facac0 !important;
    cursor: not-allowed;
    box-shadow: none
}

.shopee-button-solid--email {
    background-color: #1d9db4
}

.shopee-button-solid--facebook {
    background-color: #31589c
}

.shopee-button-solid--sms {
    background-color: #13b50b
}

.shopee-button-solid--disabled {
    box-shadow: none;
    background-color: rgba(0, 0, 0, .26)
}

.shopee-button-solid--disabled:hover {
    background-color: rgba(0, 0, 0, .26);
    cursor: not-allowed
}

.iGWmjk {
    position: relative;
    display: flex;
    min-width: 0;
    height: calc(100% - .125rem);
    background: #fff;
    border: 1px solid var(--border-color);
    border-radius: 0 .125rem .125rem 0;
    border-left: 0 solid transparent
}

.iGWmjk._5wveHV>* {
    opacity: .5
}

.btn-solid-primary {
    color: #fff;
    position: relative;
    overflow: visible;
    outline: 0;
    background: #ee4d2d
}

.btn-solid-primary.focus-visible:before,
.btn-solid-primary:focus-visible:before {
    content: "";
    width: calc(100% + var(--focus-indicator-spacing)*2);
    height: calc(100% + var(--focus-indicator-spacing)*2);
    padding: var(--focus-indicator-spacing);
    outline: var(--focus-indicator-stroke-width) solid var(--focus-indicator-color);
    position: absolute;
    border-radius: var(--focus-indicator-border-radius);

}

.btn-solid-primary:not(.btn-solid-primary--disabled):hover {
    background: #f05d40
}

.btn-solid-primary:not(.btn-solid-primary--disabled):active {
    background: #e2492b;
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.btn-solid-primary.btn-solid-primary--disabled {
    background: #facac0 !important;
    cursor: not-allowed;
    box-shadow: none
}

.btn-solid-primary--link {
    color: #fff
}

.theme--ofs .btn-solid-primary {
    position: relative;
    overflow: visible;
    outline: 0;
    background: #d0011b
}

.theme--ofs .btn-solid-primary.focus-visible:before,
.theme--ofs .btn-solid-primary:focus-visible:before {
    content: "";
    width: calc(100% + var(--focus-indicator-spacing)*2);
    height: calc(100% + var(--focus-indicator-spacing)*2);
    padding: var(--focus-indicator-spacing);
    outline: var(--focus-indicator-stroke-width) solid var(--focus-indicator-color);
    position: absolute;
    border-radius: var(--focus-indicator-border-radius);

}

.theme--ofs .btn-solid-primary:not(.btn-solid-primary--disabled):hover {
    background: #d41830
}

.theme--ofs .btn-solid-primary:not(.btn-solid-primary--disabled):active {
    background: #c6011a;
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.theme--ofs .btn-solid-primary.btn-solid-primary--disabled {
    background: #f1b3bb !important;
    cursor: not-allowed;
    box-shadow: none
}

.theme--s-mart .btn-solid-primary {
    position: relative;
    overflow: visible;
    outline: 0;
    background: #066bc8
}

.theme--s-mart .btn-solid-primary.focus-visible:before,
.theme--s-mart .btn-solid-primary:focus-visible:before {
    content: "";
    width: calc(100% + var(--focus-indicator-spacing)*2);
    height: calc(100% + var(--focus-indicator-spacing)*2);
    padding: var(--focus-indicator-spacing);
    outline: var(--focus-indicator-stroke-width) solid var(--focus-indicator-color);
    position: absolute;
    border-radius: var(--focus-indicator-border-radius);

}

.theme--s-mart .btn-solid-primary:not(.btn-solid-primary--disabled):hover {
    background: #1c78cd
}

.theme--s-mart .btn-solid-primary:not(.btn-solid-primary--disabled):active {
    background: #0666be;
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.theme--s-mart .btn-solid-primary.btn-solid-primary--disabled {
    background: #b4d3ef !important;
    cursor: not-allowed;
    box-shadow: none
}

.shopee-button-outline {
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4, 0, .6, 1);
    border: 1px solid rgba(0, 0, 0, .09);
    border-radius: 2px;
    background: transparent;
    color: rgba(0, 0, 0, .8)
}

.shopee-button-outline--normal {
    background: #fff
}

.shopee-button-outline--normal:hover {
    background: #f3f3f3
}

.shopee-button-outline--disabled {
    color: rgba(0, 0, 0, .26);
    border-color: rgba(0, 0, 0, .09)
}

.shopee-button-outline--fill {
    width: 100%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0
}

.shopee-button-outline--tributton {
    flex: none !important;
    padding: 10px 15px !important;
    text-transform: uppercase !important;
    width: 130px;
    height: 36px
}

.shopee-button-outline--tributton:hover {
    flex: none !important;
    padding: 10px 15px !important;
    color: #ee4d2d;
    border-color: #ee4d2d
}

.shopee-button-outline--primary-reverse,
.shopee-button-outline--primary:hover {
    color: #ee4d2d;
    border-color: #ee4d2d
}

.shopee-button-outline--primary-reverse:hover {
    background: #ee4d2d;
    color: #fff
}

.shopee-button-outline--complement:hover {
    color: #00bfa5;
    border-color: #00bfa5
}

.shopee-button-outline--rounded {
    border-radius: 1.25rem
}

.shopee-button-outline--rounded:hover {
    color: #ee4d2d;
    border-color: #ee4d2d
}

.t5tqdR {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 7.375rem;
    height: calc(100% - .125rem);
    position: relative;
    display: flex;
    background: linear-gradient(90deg, transparent 0, transparent .25rem, #fff 0);
    background-origin: border-box;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-right: 1px dashed var(--border-color)
}

.t5tqdR.xxRmS2 .ImEhNv {
    color: #0f0f0f
}

.m8lUCO {
    width: 6.625rem
}

.r7Ek9g {
    top: 0;
    left: 0;
    position: absolute;
    width: .25rem;
    height: 100%
}

.r7Ek9g:before {
    top: 0
}

.r7Ek9g:after,
.r7Ek9g:before {
    content: "";
    position: absolute;
    background: #fff;
    width: 100%;
    height: .1875rem;
    border-left: 1px solid var(--border-color)
}

.r7Ek9g:after {
    bottom: 0
}

.qpMNiG {
    position: absolute;
    top: .1875rem;
    left: 0;
    width: .25rem;
    height: calc(100% - .375rem);
    background: radial-gradient(circle at 0, at .375rem, transparent 0, transparent .1875rem, var(--border-color) 0, var(--border-color) .25rem, #fff 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, transparent .1875rem, var(--border-color) 0, var(--border-color) .25rem, #fff 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.qpMNiG._3f3w7M {
    background: radial-gradient(circle at 0, at .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, var(--border-color) 0, var(--border-color) .25rem, #fff 0);
    background: radial-gradient(circle at 0 .375rem, transparent 0, rgba(0, 0, 0, .03) .1875rem, var(--border-color) 0, var(--border-color) .25rem, #fff 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.qpMNiG:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background: repeating-linear-gradient(var(--border-color), var(--border-color) .125rem, transparent 0, transparent .625rem);
    background-size: 1px .625rem
}

.Zi1sIi {
    height: 3.5rem;
    width: 3.5rem;
    position: relative;
    overflow: hidden
}

.uHm6wq {
    max-height: 70%;
    max-width: 70%
}

.p0Wwx5 {
    width: 100%;
    height: 100%
}

.ImEhNv {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: .75rem;
    line-height: .875rem;
    max-height: 1.75rem;
    max-width: 90%;
    text-align: center;
    -webkit-box-pack: center;
    word-break: break-word;
    margin-top: .3125rem;
    padding: 0 .5rem
}

.xxRmS2 .ImEhNv,
.xxRmS2 .p0Wwx5 {
    opacity: .5
}

.-BSqE3 {
    text-transform: uppercase
}

.btn-light {
    outline: 0;
    background: #fff;
    color: #555;
    border: 1px solid rgba(0, 0, 0, .09);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .03);
    position: relative;
    overflow: visible
}

.btn-light.focus-visible:before,
.btn-light:focus-visible:before {
    content: "";
    width: calc(100% + var(--focus-indicator-spacing)*2);
    height: calc(100% + var(--focus-indicator-spacing)*2);
    padding: var(--focus-indicator-spacing);
    outline: var(--focus-indicator-stroke-width) solid var(--focus-indicator-color);
    position: absolute;
    border-radius: var(--focus-indicator-border-radius);

}

.btn-light:active,
.btn-light:hover {
    background: rgba(0, 0, 0, .02)
}

.btn-light:active {
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.btn-light--link {
    color: #555
}

.btn-light--disabled {
    background: #fff !important;
    color: #ccc;
    cursor: not-allowed
}

.io0LX9 {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-weight: 500;
    color: rgba(0, 0, 0, .87);
    line-height: 1.25rem
}

.kEhYtN:not(:first-child) {
    padding-left: .25rem
}

.dA2zot {
    height: 1.25rem
}

.TtMKak {
    border: 1px solid #f2f2f2;
    background-color: #fbfbfb;
    color: #bbb
}

._0ZX7\+X {
    border-radius: .125rem;
    white-space: nowrap;
    max-width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #ee4d2d;
    border: 1px solid #ee4d2d;
    padding: .0625rem .25rem
}

._0ZX7\+X,
.YsfdPb {
    overflow: hidden;
    text-overflow: ellipsis
}

.YsfdPb {
    font-size: .75rem;
    line-height: .875rem;
    align-items: center
}

.aHZeXi {
    display: flex;
    min-width: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: .3125rem
}

.aHZeXi>div:first-of-type {
    flex: 0 0 auto;
    margin-right: .3125rem;
    max-width: 80%
}

.aHZeXi>div:only-child {
    max-width: 100%
}

.aHZeXi.fwkiWm {
    margin-top: 0
}

.cancel-btn {
    padding: 10px 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 140px;
    text-transform: uppercase;
    font-size: .875rem;
    font-weight: 400;
    cursor: pointer;
    color: #555;
    margin-right: 6px;
    line-height: 1;
    background: none;
    border: 0;
    transition: background-color .1s cubic-bezier(.4, 0, .6, 1)
}

.cancel-btn:hover {
    background: #f8f8f8
}

.cancel-btn:active {
    background: #f5f5f5
}

._2uBlb8 {
    display: flex;
    color: #ee4d2d;
    font-size: .75rem;
    line-height: .875rem;
    overflow: hidden;
    border: 1px solid #ee4d2d;
    border-radius: .125rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    align-self: flex-start;
    flex: auto 0 1;
    padding: 1px .25rem;
    margin-right: .125rem
}

._2uBlb8:first-child {
    flex: 0 0 auto;
    max-width: 80%
}

._2uBlb8:only-child {
    max-width: 100%
}

._2uBlb8:last-child {
    margin-right: 0
}

.QBkrfy {
    display: flex;
    color: #d0011b;
    font-size: .75rem;
    line-height: .875rem;
    border: 1px solid #d0011b;
    border-radius: .125rem;
    white-space: nowrap;
    max-width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    align-self: flex-start;
    padding: 1px .25rem;
    margin-right: .125rem
}

.dmojYi,
.QBkrfy {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: auto 0 1
}

.I0cZOh {
    display: flex
}

.shopee-button-no-outline,
.shopee-icon-button,
.shopee-image-button {
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4, 0, .6, 1)
}

.shopee-button-no-outline {
    position: relative;
    overflow: visible;
    outline: 0;
    padding: .3125rem .4375rem;
    color: rgba(0, 0, 0, .8);
    background-color: transparent;
    border: 0
}

.shopee-button-no-outline.focus-visible:before,
.shopee-button-no-outline:focus-visible:before {
    --focus-indicator-spacing: 1px;
    content: "";
    width: calc(100% + var(--focus-indicator-spacing)*2);
    height: calc(100% + var(--focus-indicator-spacing)*2);
    padding: var(--focus-indicator-spacing);
    outline: var(--focus-indicator-stroke-width) solid var(--focus-indicator-color);
    position: absolute;
    border-radius: var(--focus-indicator-border-radius);

}

.shopee-button-no-outline--primary,
.shopee-button-no-outline:hover {
    color: #ee4d2d
}

.shopee-button-no-outline--complement {
    color: #00bfa5
}

.shopee-button-no-outline--disabled {
    color: rgba(0, 0, 0, .26);
    cursor: default
}

.shopee-button-no-outline--disabled:hover {
    color: rgba(0, 0, 0, .26)
}

.shopee-button-no-outline--tributton {
    color: rgba(0, 0, 0, .87);
    flex: none !important;
    padding: 10px 15px !important;
    text-transform: uppercase;
    height: 36px
}

.shopee-button-no-outline--tributton:hover {
    color: #ee4d2d
}

.shopee-icon-button {
    border: 1px solid rgba(0, 0, 0, .54);
    background-color: transparent;
    padding: 0
}

.shopee-image-button {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 100%;
    position: relative
}

.shopee-image-button:hover .shopee-image-button__mask {
    background-color: rgba(0, 0, 0, .7)
}

.shopee-image-button__mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .4)
}

.shopee-image-button__text {
    color: #fff;
    font-weight: 300;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 .625rem;
    width: 100%
}

.shopee-see-all-button>.shopee-button-no-outline {
    display: flex;
    align-items: center;
    color: #ee4d2d
}

.shopee-see-all-button>.shopee-button-no-outline>.icon-arrow-right {
    font-size: .625rem
}

.stardust-button {
    font-family: -apple-system, Helvetica Neue, Helvetica, Roboto, Droid Sans, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .26);
    background-color: #fff;
    color: rgba(0, 0, 0, .87);
    padding: 8px 10px
}

.stardust-button.stardust-button--active,
.stardust-button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, .09);
    border-color: rgba(0, 0, 0, .26)
}

.stardust-button--disabled {
    background-color: rgba(0, 0, 0, .26);
    border: 1px solid rgba(0, 0, 0, .26)
}

.stardust-button--disabled.stardust-button--active,
.stardust-button--disabled:hover {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, .26);
    border: 1px solid rgba(0, 0, 0, .26)
}

.stardust-button--block {
    display: block;
    width: 100%
}

.stardust-button--block+.stardust-button--block {
    margin-top: 5px
}

.stardust-button--large {
    font-size: 16px;
    border-radius: 6px;
    padding: 12px 14px
}

.stardust-button--large,
.stardust-button--small {
    font-family: -apple-system, Helvetica Neue, Helvetica, Roboto, Droid Sans, Arial, sans-serif;
    font-weight: 400
}

.stardust-button--small {
    font-size: 12px;
    border-radius: 3px;
    padding: 6px 8px
}

.stardust-button--primary {
    background-color: #ee4d2d;
    border-color: #cd3011;
    color: #fff
}

.stardust-button--primary.stardust-button--active,
.stardust-button--primary:hover {
    background-color: #d73211;
    border-color: #ba2b0f
}

.stardust-button--primary.stardust-button--disabled {
    opacity: .7
}

.stardust-button--primary.stardust-button--active.stardust-button--disabled,
.stardust-button--primary:hover.stardust-button--disabled {
    background-color: #ee4d2d;
    border-color: #cd3011
}

.stardust-button--warning {
    background-color: #ff424f;
    border-color: #ef0010;
    color: #fff
}

.stardust-button--warning.stardust-button--active,
.stardust-button--warning:hover {
    background-color: #fa0011;
    border-color: #e50010
}

.stardust-button--warning.stardust-button--disabled {
    opacity: .7
}

.stardust-button--warning.stardust-button--active.stardust-button--disabled,
.stardust-button--warning:hover.stardust-button--disabled {
    background-color: #ff424f;
    border-color: #ef0010
}

.stardust-button--outline-primary {
    background-color: transparent;
    border-color: #ee4d2d;
    color: #ee4d2d
}

.stardust-button--outline-primary.stardust-button--active,
.stardust-button--outline-primary:hover {
    background-color: hsla(0, 0%, 100%, .3);
    border-color: #d73211
}

.stardust-button--outline-primary.stardust-button--disabled {
    opacity: .7
}

.stardust-button--outline-primary.stardust-button--active.stardust-button--disabled,
.stardust-button--outline-primary:hover.stardust-button--disabled {
    background-color: transparent;
    border-color: #ee4d2d
}

.stardust-button--outline-white {
    background-color: transparent;
    border-color: #fff;
    color: #fff
}

.stardust-button--outline-white.stardust-button--active,
.stardust-button--outline-white:hover {
    background-color: hsla(0, 0%, 100%, .3);
    border-color: #fff
}

.stardust-button--outline-white.stardust-button--disabled {
    opacity: .7
}

.stardust-button--outline-white.stardust-button--active.stardust-button--disabled,
.stardust-button--outline-white:hover.stardust-button--disabled {
    background-color: transparent;
    border-color: #fff
}

.stardust-button--ghost-primary {
    background-color: transparent;
    border-color: transparent;
    color: #ee4d2d
}

.stardust-button--ghost-primary.stardust-button--active,
.stardust-button--ghost-primary:hover {
    background-color: rgba(0, 0, 0, .09);
    border-color: rgba(0, 0, 0, .09)
}

.stardust-button--ghost-primary.stardust-button--disabled {
    opacity: .7
}

.stardust-button--ghost-primary.stardust-button--active.stardust-button--disabled,
.stardust-button--ghost-primary:hover.stardust-button--disabled {
    background-color: transparent;
    border-color: transparent
}

.stardust-toast {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: opacity .1s cubic-bezier(.4, 0, .6, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all
}

.stardust-toast--hidden {
    opacity: 0;
    pointer-events: none
}

.stardust-toast__container {
    font-family: -apple-system, Helvetica Neue, Helvetica, Roboto, Droid Sans, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    background-color: rgba(0, 0, 0, .65);
    color: #fff;
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    pointer-events: all;
    cursor: default;
    border-radius: 7px;
    pointer-events: inherit
}

.stardust-toast__icon svg {
    width: 30px;
    height: 30px;
    margin-bottom: 7px
}

.stardust-toast__text {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
    word-wrap: break-word
}

.shopee-modal__transition-appear {
    opacity: .01
}

.shopee-modal__transition-appear.shopee-modal__transition-appear-active {
    opacity: 1;
    transition: opacity .2s
}

.shopee-modal__transition-enter {
    opacity: .01
}

.shopee-modal__transition-enter.shopee-modal__transition-enter-active {
    opacity: 1;
    transition: opacity .2s
}

.shopee-modal__transition-exit {
    opacity: 1
}

.shopee-modal__transition-exit.shopee-modal__transition-exit-active {
    opacity: .01;
    transition: opacity .2s
}

.confirm-popup {
    flex-direction: column;
    width: 440px;
    font-size: 1rem;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center
}

.confirm-popup__button-group {
    display: flex;
    justify-content: center;
    margin-top: 2rem
}

.confirm-popup .shopee-button-outline--confirm-popup,
.confirm-popup .shopee-button-solid--confirm-popup {
    width: 6.25rem;
    min-height: 2.5rem;
    font-size: .875rem;
    text-transform: uppercase;
    margin: .46875rem
}

.DgeefF {
    flex-direction: column;
    width: 440px;
    font-size: 1rem;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
    width: 27.5rem;
    overflow: hidden
}

.IVlt4n {
    font-size: 1.25rem;
    line-height: 1.4375rem;
    color: #333;
    margin: 0;
    font-weight: 400
}

.EKCFvp,
.IVlt4n {
    word-break: break-word
}

.EKCFvp {
    font-size: .875rem;
    line-height: 1.1875rem;
    color: rgba(0, 0, 0, .65);
    margin: 1.3125rem 0 1.875rem
}

.ESrzaR {
    display: flex;
    justify-content: flex-end
}

.ESrzaR .btn {
    text-transform: uppercase;
    padding: .75rem .625rem;
    overflow: hidden;
    text-overflow: ellipsis
}

.ESrzaR .btn-light {
    margin-right: 1.875rem;
    border: 0
}

.confirm-two-popup {
    flex-direction: column;
    width: 440px;
    font-size: 1rem;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center
}

.confirm-two-popup__button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.8125rem;
    width: 100%
}

.confirm-two-popup .shopee-button-no-outline--confirm-two-popup,
.confirm-two-popup .shopee-button-solid--confirm-two-popup {
    width: 7.5rem;
    min-height: 2.5rem;
    font-size: .875rem;
    text-transform: uppercase;
    padding: .75rem;
    line-height: 1rem;
    font-weight: 500;
    font-family: Roboto
}

.confirm-two-popup .shopee-button-no-outline--confirm-two-popup {
    margin-right: 1.25rem;
    color: #000;
    opacity: .54
}

.A-Y3\+O {
    -webkit-animation: rotation 2s linear infinite;
    animation: rotation 2s linear infinite
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.zQrHUB {
    position: absolute;
    top: 0;
    left: 0
}

.NmK42O {
    width: 3.625rem
}

.nU0at\+ {
    height: 1.75rem;
    padding: 0 .5625rem;
    width: 3.4375rem;
    white-space: nowrap
}

.nU0at\+.btn-solid-primary.btn-solid-primary--disabled {
    color: rgba(0, 0, 0, .26);
    background: rgba(0, 0, 0, .09) !important
}

.nU0at\+.U1jy6F {
    color: #ee4d2d;
    border: 1px solid #ee4d2d;
    background: transparent !important
}

.nU0at\+.U1jy6F.MtOWNL {
    font-size: .6875rem
}

._9Bb5Ux {
    color: #fff
}

.O8FF-1 {
    display: flex;
    height: 100%;
    align-items: center
}

.hJkQJZ {
    padding: 1.875rem 1.875rem 1.25rem;
    width: 34.375rem;
    position: relative;
    overflow: hidden;
    border-radius: .1875rem;
    background-color: #fff;
    box-shadow: 0 .1875rem .625rem 0 rgba(0, 0, 0, .14);
    display: flex;
    flex-direction: row
}

.EYTa3s {
    margin-bottom: .5625rem;
    font-size: 1.375rem;
    font-weight: 600;
    color: #333
}

.\+NnLpC {
    margin-bottom: 1.625rem;
    font-size: 1.25rem;
    font-weight: 400;
    color: #333
}

.pSn84E {
    padding-right: 2.4375rem
}

.H0AyNd {
    display: flex;
    flex-direction: column;
    align-items: center
}

.Rxv3AD {
    overflow: hidden;
    margin-bottom: .6875rem
}

.-fJPqN {
    margin: -.625rem -.3125rem -.9375rem;
    width: 10rem;
    height: 10rem
}

.GLQwvw,
.iUrqq2 {
    width: 9.625rem;
    height: 3.1875rem;
    display: inline-block;
    background-repeat: no-repeat;
    margin-right: .625rem
}

.iUrqq2 {
    background-position-x: -.625rem;
    background-position-y: -.6875rem;
    background-size: 113% 146%
}

.GLQwvw {
    background-size: 100%
}

.Dd5yCb {
    display: flex;
    align-items: center
}

.h9f8hY,
.txHBdI {
    height: 1rem;
    padding: .1875rem
}

.fr1NYK.hJkQJZ {
    width: 34.8125rem;
    padding: 1rem 1.75rem 1.25rem
}

.fr1NYK .EYTa3s {
    padding-top: 2.0625rem;
    margin-bottom: 1rem
}

.fr1NYK .iUrqq2 {
    width: 7.5rem;
    height: 2.1875rem;
    background-position-x: -.5625rem;
    background-position-y: -.5625rem;
    background-size: 115% 151%
}

.fr1NYK .GLQwvw {
    width: 6.6875rem;
    height: 2.1875rem
}

.fr1NYK .Wp3\+sK {
    width: 6.875rem;
    height: 2.1875rem;
    background-size: 100% 114%;
    background-position-y: -.125rem;
    display: inline-block;
    background-repeat: no-repeat
}

.fr1NYK .pSn84E {
    padding-right: 1.4375rem
}

.fr1NYK .H0AyNd {
    justify-content: center
}

.fr1NYK .-fJPqN {
    width: 11rem;
    height: 11rem;
    margin: 0
}

.fr1NYK .Rxv3AD {
    margin-bottom: -.25rem
}

.fr1NYK .h9f8hY,
.fr1NYK .txHBdI,
.fr1NYK .VBwhsX {
    height: .75rem;
    padding: 0;
    margin-right: .375rem;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor
}

.fr1NYK .VBwhsX {
    margin-right: 0
}

.xOZo0S {
    z-index: 600
}

.KAKwr- {
    max-width: 17.1875rem;
    padding: .75rem;
    word-break: break-word
}

.TuN0NU {
    display: flex;
    height: 100%;
    align-items: center
}

.icon-popup {
    flex-direction: column;
    width: 440px;
    font-size: 1rem;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center
}

.Mm1KCo {
    background: #fff;
    color: #ee4d2d;
    border: 1px solid #ee4d2d;
    width: 4rem;
    height: 1.75rem;
    white-space: nowrap
}

.Mm1KCo--disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, .26);
    border-color: rgba(0, 0, 0, .26)
}

.error-popup .icon-error {
    margin-bottom: 14px;
    width: 50px;
    height: 50px
}

.vIciPT {
    width: 100%;
    min-width: 0
}

.eKNyBv {
    flex: 1;
    max-width: 100%;
    padding: 0 1.25rem;
    min-width: 0
}

.eKNyBv.Fy-baj {
    background: #0046ab
}

.jedHdg {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.rLZ5mI {
    margin-top: .3125rem
}

.info-popup {
    flex-direction: column;
    width: 440px;
    font-size: 1rem;
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5)
}

.info-popup__head {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px
}

.info-popup__head .shopee-svg-icon {
    height: 24px;
    width: 24px
}

.info-popup__icon {
    margin-right: 10px
}

.info-popup__title {
    font-size: 18px;
    color: #333
}

.info-popup__message {
    font-size: 16px;
    color: #555
}

.info-popup__buttons {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    width: 100%
}

.info-popup__btn {
    width: 6.25rem;
    min-height: 2.5rem;
    font-size: .875rem;
    text-transform: uppercase;
    margin: .46875rem
}

.MFIbZs {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 7.375rem;
    height: 100%;
    position: relative;
    display: flex;
    background: linear-gradient(90deg, transparent 0, transparent .25rem, #ee4d2d 0)
}

.MFIbZs.TrZtnU {
    background: linear-gradient(90deg, transparent 0, transparent .25rem, #bdbdbd 0);
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.MFIbZs ._7UTBSr {
    position: absolute;
    top: .3125rem;
    left: 0;
    width: .25rem;
    height: calc(100% - .4375rem);
    background: radial-gradient(circle at 0, at .25rem, transparent 0, transparent .1875rem, #ee4d2d 0);
    background: radial-gradient(circle at 0 .25rem, transparent 0, transparent .1875rem, #ee4d2d 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.MFIbZs ._7UTBSr.TrZtnU {
    background: radial-gradient(circle at 0, at .25rem, transparent 0, transparent .1875rem, #bdbdbd 0);
    background: radial-gradient(circle at 0 .25rem, transparent 0, transparent .1875rem, #bdbdbd 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.MFIbZs ._7UTBSr:before {
    top: -.3125rem;
    height: .3125rem;
    border-radius: .125rem 0 0 0
}

.MFIbZs ._7UTBSr:after,
.MFIbZs ._7UTBSr:before {
    content: "";
    position: absolute;
    width: 100%;
    background: #ee4d2d
}

.MFIbZs ._7UTBSr:after {
    bottom: -.125rem;
    left: 0;
    height: .125rem;
    border-radius: 0 0 0 .125rem
}

.MFIbZs ._7UTBSr.TrZtnU:after,
.MFIbZs ._7UTBSr.TrZtnU:before {
    background: #bdbdbd
}

.f5VFyh {
    background: linear-gradient(90deg, transparent 0, transparent .25rem, #26aa99 0)
}

.f5VFyh.TrZtnU {
    background: linear-gradient(90deg, transparent 0, transparent .25rem, #bdbdbd 0);
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.f5VFyh ._7UTBSr {
    position: absolute;
    top: .3125rem;
    left: 0;
    width: .25rem;
    height: calc(100% - .4375rem);
    background: radial-gradient(circle at 0, at .25rem, transparent 0, transparent .1875rem, #26aa99 0);
    background: radial-gradient(circle at 0 .25rem, transparent 0, transparent .1875rem, #26aa99 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.f5VFyh ._7UTBSr.TrZtnU {
    background: radial-gradient(circle at 0, at .25rem, transparent 0, transparent .1875rem, #bdbdbd 0);
    background: radial-gradient(circle at 0 .25rem, transparent 0, transparent .1875rem, #bdbdbd 0);
    background-size: .25rem .625rem;
    background-repeat: repeat-y
}

.f5VFyh ._7UTBSr:before {
    top: -.3125rem;
    height: .3125rem;
    border-radius: .125rem 0 0 0
}

.f5VFyh ._7UTBSr:after,
.f5VFyh ._7UTBSr:before {
    content: "";
    position: absolute;
    width: 100%;
    background: #26aa99
}

.f5VFyh ._7UTBSr:after {
    bottom: -.125rem;
    left: 0;
    height: .125rem;
    border-radius: 0 0 0 .125rem
}

.f5VFyh ._7UTBSr.TrZtnU:after,
.f5VFyh ._7UTBSr.TrZtnU:before {
    background: #bdbdbd
}

.JSLjs1 {
    height: 3.5rem;
    width: 3.5rem;
    overflow: hidden
}

._4LE7ze {
    max-width: 80%;
    max-height: 80%;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1)
}

.DSipvx {
    width: 100%;
    height: 100%
}

.Vz3Jnz {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: .75rem;
    line-height: .875rem;
    max-height: 1.75rem;
    max-width: 90%;
    color: #fff;
    text-align: center;
    -webkit-box-pack: center;
    word-break: break-word;
    margin-top: .3125rem;
    padding: 0 .5rem
}

.HhQc6D {
    text-transform: uppercase
}

.success-popup__icon {
    margin-bottom: 14px;
    width: 50px;
    height: 50px
}

.nvYLLx {
    position: relative
}

.CjCAKp,
.nvYLLx {
    height: 100%
}

.c6rn4m {
    position: relative
}

.c6rn4m>a {
    color: inherit
}

.kYmueu {
    flex: 1
}

.XC7EBJ {
    position: absolute;
    left: -.3125rem;
    top: .25rem;
    display: flex;
    padding: .125rem .3125rem;
    border-radius: .125rem .125rem .125rem 0;
    background: #fb0;
    color: #fff;
    font-size: .75rem;
    line-height: .875rem
}

.XC7EBJ:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 1px;
    top: calc(100% + 1px);
    border: .25rem solid transparent;
    border-left-color: #dfa400;
    -webkit-transform: rotate(-45deg) translate(50%, -50%);
    transform: rotate(-45deg) translate(50%, -50%)
}

.qbDhhA {
    font-weight: 700
}

._37vWHr {
    background: #999
}

._37vWHr:after {
    border-left-color: #757575
}

.xxD4dr:hover {
    cursor: pointer
}

.ScnC53 {
    background: #fef7e7
}

.jqQrzk {
    padding: 1.125rem 1.25rem .625rem 0;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative
}

.shopee-alert-popup {
    background-color: #fff
}

.shopee-alert-popup__btn {
    flex: 1;
    max-width: 100%
}

.shopeeCopyMessage {
    width: 100%;
    text-align: center;
    margin-top: 44px;
    font-size: 20px;
    margin-bottom: 66px;
    outline: none;
    background-color: transparent;
    border: 0 solid;
    color: #f5a623
}

.shopeeTributtonTitle {
    color: #555;
    font-size: 18px;
    margin-top: 6px;
    margin-left: 10px
}

.shopeeTributtonButtons {
    justify-content: flex-end;
    margin-top: 0
}

.fade-enter {
    opacity: .01
}

.fade-enter-active {
    opacity: 1;
    transition: all .2s ease-in-out
}

.fade-exit {
    opacity: 1
}

.fade-exit-active {
    opacity: .01;
    transition: all .2s ease-in-out
}

.wrap {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9999
}

.fadescale-enter {
    opacity: .01;
    -webkit-transform: translate(-50%, -50%) scale(.5);
    transform: translate(-50%, -50%) scale(.5)
}

.fadescale-enter-active {
    transition: all .2s ease-in-out
}

.fadescale-enter-active,
.fadescale-exit {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
}

.fadescale-exit-active {
    -webkit-transform: translate(-50%, -50%) scale(.5);
    transform: translate(-50%, -50%) scale(.5);
    opacity: .01;
    transition: all .2s ease-in-out
}

.SrXQqH {
    padding: 0;
    border: 0;
    position: relative;
    color: #ee4d2d;
    font-size: .875rem;
    margin-right: .75rem;
    cursor: pointer
}

.SrXQqH:after {
    border: solid #ee4d2d;
    border-width: 0 1px 1px 0;
    padding: 2.5px;
    position: absolute;
    content: "";
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    height: 0%;
    width: 0;
    bottom: .3125rem;
    margin-left: .3125rem
}

.gJh\+4V {
    position: absolute;
    display: flex;
    padding: .125rem .25rem;
    border-radius: .125rem .125rem .125rem 0;
    color: #fff;
    font-size: .625rem;
    line-height: .75rem
}

.jjYXLV {
    left: -.1875rem;
    top: .3125rem
}

.vrXZpQ {
    left: -.1875rem;
    top: -.3125rem
}

.gJh\+4V:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 1px;
    top: calc(100% + 1px);
    border: .1875rem solid transparent;
    -webkit-transform: rotate(-45deg) translate(50%, -50%);
    transform: rotate(-45deg) translate(50%, -50%)
}

.fpTTCj {
    /* background: #999 */
    background-color: mediumvioletred;
}

.fpTTCj.gJh\+4V:after {
    border-left: .1875rem solid #757575
}

.JNTxQ- {
    background: #fb0
}

.JNTxQ-.gJh\+4V:after {
    border-left: .1875rem solid #dfa400
}

._5TUxKT {
    width: 100%;
    min-width: 0
}

.RystvV {
    flex: 1;
    max-width: 100%;
    padding: 0 .75rem;
    min-width: 0
}

.hX0Gca {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.T\+U75- {
    margin-top: .3125rem
}

.V-tMFL {
    color: #508be3
}

.coi2J8 {
    cursor: pointer
}

.n8nBPK {
    text-transform: capitalize
}

.xB5rt8 {
    justify-self: flex-end
}

.xB5rt8>a {
    color: #508be3;
    font-size: .75rem;
    font-weight: 500
}

.iSTWG9 {
    width: 100%;
    min-width: 0
}

.iSTWG9.sJ4e0o {
    background: #fef7e7
}

.qVvYmp {
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.T5FNT8 {
    justify-content: flex-end
}

.V53qfK {
    padding: 0;
    border: 0;
    position: relative;
    color: #ee4d2d;
    font-size: .875rem;
    margin-right: .75rem;
    cursor: pointer
}

.V53qfK+.bSmg7g {
    justify-self: auto
}

.V53qfK:after {
    border: solid #ee4d2d;
    border-width: 0 1px 1px 0;
    padding: .2125rem;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    position: absolute;
    content: "";
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    display: block;
    right: -.625rem;
    bottom: .0625rem;
    margin-left: .3125rem
}

.V53qfK.liHlyF:after {
    border-width: 0 1px 1px 0
}

a {
    cursor: pointer
}

.w-Zkas {
    align-items: flex-end
}

.wUBG3M {
    color: #979797
}

.bNSt-c {
    padding: .75rem .75rem .75rem 0;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.bNSt-c,
.nFRDrh {
    position: relative
}

.EnEzVZ,
.nFRDrh {
    height: 100%
}

.pLgFT5 {
    position: relative
}

.pLgFT5>a {
    color: inherit
}

.QEwfak {
    position: absolute;
    left: -.3125rem;
    top: .25rem;
    display: flex;
    padding: .125rem .3125rem;
    border-radius: .125rem .125rem .125rem 0;
    background: #fb0;
    color: #fff;
    font-size: .75rem;
    line-height: .875rem
}

.QEwfak:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 1px;
    top: calc(100% + 1px);
    border: .25rem solid transparent;
    border-left-color: #dfa400;
    -webkit-transform: rotate(-45deg) translate(50%, -50%);
    transform: rotate(-45deg) translate(50%, -50%)
}

._3hY7B8 {
    font-weight: 700
}

.mFnuqv {
    background: #999
}

.mFnuqv:after {
    border-left-color: #757575
}

._42LVto:hover {
    cursor: pointer
}

.Cs-ETN {
    background: #fef7e7
}

.VJIFZr {
    display: inline-flex;
    margin-right: .25rem;
    padding: 0 .25rem;
    height: 1rem;
    line-height: 1rem;
    text-transform: capitalize;
    border-radius: .125rem;
    font-weight: 400;
    font-size: .625rem;
    vertical-align: middle
}

.QhNwXs {
    background-color: #ffbf00;
    color: #fff
}

.pHr7Kb {
    background-color: #ee4d2d;
    color: #fff
}

.vDEmcq {
    background-color: #ff424f;
    color: #fff
}


/*# sourceMappingURL=https://sourcemap.webfe.shopee.io/pcmall-voucher/_/526.6cb796db53f9bf9289c6.legacy.css.map*/

/*!
 * Project: 'pcmall';
 * Module: 'voucher';
 * Tag: 'pc-v5.15.0';
 * Branch: 'heads/pc-v5.15.0';
 * Last commit: 'chore(release): pc-v5.15.0' (0d6fac332d847f805373ab31341b4088e62cec7d);
 * Updated: '2022-07-21T02:35:49.626Z';
 */
.input-with-validator {
    display: flex;
    align-items: center;
    background-color: #fff;
    position: relative;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, .14);
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, .02);
    color: #222;
    height: 40px;
    padding: 10px;
    transition: border-color .1s ease
}

.input-with-validator--focus {
    border-color: rgba(0, 0, 0, .54);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .14)
}

.input-with-validator--error {
    border-color: #ff424f;
    background-color: #fff9fa
}

.input-with-validator input {
    flex: 1;
    font-size: 14px;
    background: transparent;
    outline: none;
    box-shadow: none;
    border: 0
}

.input-with-validator input:-moz-placeholder {
    color: #bbb
}

.input-with-validator input:-ms-input-placeholder {
    color: #bbb
}

.input-with-validator input::-webkit-input-placeholder {
    color: #bbb
}

.input-with-validator input::-moz-placeholder {
    color: #bbb
}

.input-with-validator:hover {
    box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, .05)
}

.input-with-validator__error-message {
    font-size: 12px;
    color: #ff424f;
    height: 20px;
    display: flex;
    align-items: center
}

.input-with-validator__info-message,
.input-with-validator__success-message {
    font-size: 12px;
    height: 20px
}

.input-with-validator__cancel-button {
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: 14px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

._3Y9kTz {
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .03);
    border-color: rgba(0, 0, 0, .09)
}

._3Y9kTz.stardust-button.stardust-button--active,
._3Y9kTz:active,
._3Y9kTz:focus,
._3Y9kTz:hover {
    background-color: rgba(0, 0, 0, .02);
    border-color: rgba(0, 0, 0, .09);
    outline: none
}

._3Y9kTz.stardust-button.stardust-button--active,
._3Y9kTz:active {
    box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, .05)
}

._3Y9kTz.stardust-button--disabled,
._3Y9kTz.stardust-button--disabled:hover {
    background-color: #fff;
    opacity: unset;
    color: #ccc;
    border-color: rgba(0, 0, 0, .05)
}

@-webkit-keyframes movement {
    0% {
        -webkit-transform: translateY(2px);
        transform: translateY(2px)
    }

    50% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px)
    }

    to {
        -webkit-transform: translateY(2px);
        transform: translateY(2px)
    }
}

@keyframes movement {
    0% {
        -webkit-transform: translateY(2px);
        transform: translateY(2px)
    }

    50% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px)
    }

    to {
        -webkit-transform: translateY(2px);
        transform: translateY(2px)
    }
}

.stardust-spinner--hidden {
    display: none
}

.stardust-spinner__background {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, .4);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center
}

.stardust-spinner__background--no-overlay {
    position: static;
    background-color: transparent;
    width: auto;
    height: auto
}

.stardust-spinner__background--no-overlay .stardust-spinner__main {
    padding: 0;
    border-radius: 0
}

.stardust-spinner__main {
    flex: 0 1 auto;
    padding: 1rem;
    border-radius: 1rem;
    background: #fff;
    color: #000
}

.stardust-spinner__spinner {
    -webkit-animation: movement .4s linear infinite;
    animation: movement .4s linear infinite
}

.stardust-spinner__spinner:nth-child(2) {
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.stardust-spinner__spinner:nth-child(3) {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.r58cXf {
    display: flex;
    padding: .9375rem;
    align-items: center;
    background: #f8f8f8
}

.r58cXf .input-with-validator-wrapper {
    position: relative
}

.r58cXf .input-with-validator>input {
    width: 100%;
    flex: none
}

.r58cXf .input-with-validator__error-message,
.r58cXf .input-with-validator__info-message,
.r58cXf .input-with-validator__success-message {
    position: absolute;
    border-radius: 2px;
    padding: .625rem;
    height: auto;
    width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    top: 100%;
    z-index: 2;
    margin-top: .0625rem;
    word-break: break-word
}

.r58cXf .input-with-validator__error-message:empty,
.r58cXf .input-with-validator__info-message:empty,
.r58cXf .input-with-validator__success-message:empty {
    display: none
}

.r58cXf .input-with-validator__error-message {
    background: #fff9fa;
    border: 1px solid #ff424f
}

.r58cXf .input-with-validator__error-message .shopee-svg-icon {
    border: 1px solid #ff424f;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0
}

.r58cXf .input-with-validator__success-message {
    background: #fcfffa;
    border: 1px solid #6c0
}

.r58cXf .input-with-validator__info-message {
    background: #fcfdff;
    border: 1px solid #d0e4f2
}

.r58cXf .dtBpAT {
    flex: 1
}

.r58cXf .kvLi4R {
    flex-shrink: 0;
    text-transform: capitalize;
    margin-right: .6875rem
}

.r58cXf ._3Ilx46._3Ilx46 {
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: .5rem;
    width: 5.875rem;
    height: 2.5rem;
    flex-shrink: 0
}

.r58cXf ._3Ilx46.FYL-Gt {
    color: #ccc
}

.r58cXf ._3Ilx46.FYL-Gt:hover {
    background-color: #fff
}

._11To29 {
    background: #fff8e4;
    width: 100%;
    height: 2.375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ee4d2d;
    padding: 0 .625rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: .875rem
}

._11To29>font {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

._11To29 .nDidWF {
    background: #fffbf0;
    color: rgba(0, 0, 0, .65)
}

._11To29 .-xn7-m {
    display: flex;
    align-items: center
}

.m71zL1 {
    margin-right: .3125rem
}

.Z4fdNn {
    color: #ee4d2d;
    display: flex;
    align-items: center;
    margin-left: .75rem;
    cursor: pointer
}

.Z4fdNn svg {
    fill: #ee4d2d;
    height: .625rem
}

._2B0ZkF {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, .14);
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, .02);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center
}

.GgGLnc {
    background-color: #ee4d2d;
    border-width: 0
}

.z9U4WD {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, .09)
}

.GgGLnc.z9U4WD {
    background-color: #e7e7e7
}

.YLpxT4 {
    color: #fff;
    height: .5625rem;
    width: .5625rem
}

.Sw3kAk {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.WxXv5v {
    cursor: not-allowed
}

._4qcuPt {
    width: 100%;
    position: relative;
    margin-bottom: .9375rem
}

.KP835f {
    height: 7.375rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.gUjGTy {
    position: relative
}

.oiwxkc {
    position: absolute;
    left: -.3125rem;
    top: .25rem;
    display: flex;
    padding: .125rem .3125rem;
    border-radius: .125rem .125rem .125rem 0;
    background: #fb0;
    color: #fff;
    font-size: .75rem;
    line-height: .875rem
}

.oiwxkc:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 2px;
    top: calc(100% + 1px);
    border: .25rem solid transparent;
    border-left-color: #dfa400;
    -webkit-transform: rotate(-45deg) translate(50%, -50%);
    transform: rotate(-45deg) translate(50%, -50%)
}

.bUIIfa {
    background: #999
}

.bUIIfa:after {
    border-left-color: #757575
}

.LyEWB4 {
    font-weight: 700
}

._60LqN6 {
    flex: 1
}

.XiuGNt {
    display: flex;
    flex-direction: column;
    justify-content: center
}

._692aQb>a {
    color: #508be3;
    font-size: .75rem;
    font-weight: 500
}

._95Mhu6>a {
    color: rgba(0, 0, 0, .87)
}

.fXryjH {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: .75rem .75rem .75rem 0;
    flex-shrink: 0
}

.GDoNZm {
    opacity: .5
}

.fXryjH.jVyCvY {
    opacity: 1
}

.jve8sn {
    background: #fef7e7
}

._989u2k,
.jve8sn {
    transition: background 1s ease
}

._989u2k {
    background: #fff
}

.fIFXqQ {
    visibility: hidden
}

.N4d5hR {
    display: flex;
    align-items: center;
    background: #fff8e4;
    color: #ee4d2d;
    padding: .5rem
}

._4-kIqn {
    margin-right: .3125rem
}

.QuAZ0d {
    opacity: .6
}

.vQUc\+Q {
    background: #999;
    position: absolute;
    left: -.3125rem;
    top: .25rem;
    display: flex;
    padding: .125rem .3125rem;
    border-radius: .125rem .125rem .125rem 0;
    color: #fff;
    font-size: .75rem;
    line-height: .875rem
}

.vQUc\+Q:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 1px;
    top: calc(100% + 1px);
    border: .25rem solid transparent;
    border-left-color: #757575;
    -webkit-transform: rotate(-45deg) translate(50%, -50%);
    transform: rotate(-45deg) translate(50%, -50%)
}

.FSFSpc {
    width: 100%;
    margin-bottom: .9375rem
}

.U0bjBP {
    height: 7.375rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.WD4GtN {
    position: relative
}

.FSFSpc {
    display: flex;
    flex-direction: column
}

.JiEacg {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 2.1875rem;
    padding: 0 .75rem
}

._2jX8OW {
    display: flex;
    align-items: center;
    background: #fff8e4;
    color: #ee4d2d;
    padding: .5rem
}

.JiEacg {
    background: #fffbf0;
    color: rgba(0, 0, 0, .65)
}

.c-6oe7 {
    margin-right: .3125rem
}

.SJcecu {
    flex: 1
}

.uEtbTV {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.r2IIGm>a {
    color: #508be3;
    font-size: .75rem;
    font-weight: 500
}

.W3t5le {
    flex: 1;
    display: flex;
    align-items: center
}

.JR-5UM {
    align-items: flex-end
}

.KaAPQW {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: .75rem .75rem .75rem 0;
    flex-shrink: 0
}

.oRzPvb {
    background: #fef7e7
}

.aoD7ll,
.oRzPvb {
    transition: background 1s ease
}

.aoD7ll {
    background: #fff
}

.agVqol {
    visibility: hidden
}

.u6U6gK {
    opacity: .5
}

.C8GVIk {
    opacity: .6
}

.stardust-dropdown {
    position: relative
}

.stardust-dropdown__item-body {
    transition: height .4s cubic-bezier(.4, 0, .2, 1), opacity .4s cubic-bezier(.4, 0, .2, 1);
    height: 0;
    opacity: 0;
    overflow: hidden
}

.stardust-dropdown__item-body--open {
    height: auto;
    opacity: 1
}

.ARaxOb {
    border-top: 1px solid #f0f0f0;
    margin-bottom: 1.25rem
}

.qaXa1N {
    margin-left: .5rem
}

.eUe0-2 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.pQCej9 {
    display: flex;
    flex-direction: column
}

.pQCej9 .stardust-dropdown__item-body--open {
    overflow: visible
}

.pQCej9 .stardust-dropdown__item-header {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: -.9375rem;
    padding: .75rem;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1
}

.pQCej9 .stardust-dropdown__item-body :first-child {
    margin-top: 0
}

.stardust-popover {
    position: relative
}

.stardust-popover.focus-visible,
.stardust-popover:focus-visible {
    outline: 2px solid #000;
    border-radius: 2px
}

.stardust-popover__popover {
    will-change: transform;
    position: absolute;
    z-index: -1
}

.stardust-popover__popover--show {
    z-index: 3;
    -webkit-animation: popover-enter-animation .2s cubic-bezier(.4, 0, .6, 1);
    animation: popover-enter-animation .2s cubic-bezier(.4, 0, .6, 1);
    opacity: 1
}

.stardust-popover__popover--border {
    border: 1px solid rgba(0, 0, 0, .09)
}

.stardust-popover__popover--hide {
    -webkit-animation: popover-hide-animation .2s cubic-bezier(.4, 0, .6, 1);
    animation: popover-hide-animation .2s cubic-bezier(.4, 0, .6, 1)
}

.stardust-popover__arrow {
    z-index: 3;
    position: absolute;
    will-change: transform
}

.stardust-popover__arrow--inner {
    position: absolute;
    left: -8px;
    width: 0;
    height: 0
}

@-webkit-keyframes popover-enter-animation {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes popover-enter-animation {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes popover-hide-animation {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    to {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }
}

@keyframes popover-hide-animation {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    to {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }
}

.vThpD9 {
    padding: 1rem;
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .05);
    font-size: .875rem;
    line-height: 1rem;
    width: 14.6875rem
}

.vThpD9 h3 {
    font-weight: 500;
    text-align: center;
    margin: 0 0 1.5rem
}

.mXoifT {
    margin: .9375rem 0 .3125rem
}

.mXoifT h4 {
    font-weight: 500;
    margin: 0 0 .9375rem
}

.mXoifT p {
    font-size: .75rem;
    line-height: .875rem;
    color: rgba(0, 0, 0, .54)
}

._12Mu6b {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

._12Mu6b h3 {
    color: rgba(0, 0, 0, .65);
    margin-top: .9375rem;
    margin-bottom: .625rem;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    text-align: center
}

._12Mu6b p {
    color: rgba(0, 0, 0, .54);
    line-height: 1.125rem;
    text-align: center;
    padding: 0 4.1875rem;
    font-size: .875rem;
    margin: 0
}

.VWD9vJ {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.VWD9vJ h3 {
    color: rgba(0, 0, 0, .65);
    margin-top: .9375rem;
    margin-bottom: .625rem;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    text-align: center
}

.VWD9vJ p {
    color: rgba(0, 0, 0, .54);
    line-height: 1.125rem;
    text-align: center;
    padding: 0 4.1875rem;
    font-size: .875rem;
    margin: 0
}

._5UKHdD {
    width: 140px;
    text-transform: uppercase
}

.shopee-popup-form {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .05);
    border-radius: .125rem;
    border-radius: 3px;
    width: 500px;
    max-width: 100%;
    padding: 30px 30px 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #fff;
    overflow: hidden
}

.shopee-popup-form__header {
    display: flex;
    align-items: center;
    height: 60px
}

.shopee-popup-form__title {
    font-weight: 400;
    font-size: 1.25rem;
    color: #222;
    text-transform: capitalize;
    display: flex;
    align-items: center
}

.shopee-popup-form__subtitle {
    margin-top: -15px;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.0625rem;
    color: #bbb;
    text-transform: capitalize
}

.shopee-popup-form__back-btn {
    margin-right: .875rem;
    cursor: pointer;
    color: rgba(0, 0, 0, .4);
    display: inline-block
}

.shopee-popup-form__back-btn>.icon-back {
    font-size: .9375rem
}

.shopee-popup-form__main {
    flex: 1;
    overflow-y: auto;
    padding-right: 50px;
    margin-right: -50px;
    padding-left: 50px;
    margin-left: -50px;
    position: relative
}

.shopee-popup-form__main::-webkit-scrollbar {
    display: none
}

.shopee-popup-form__main-container {
    margin-bottom: 84px
}

.shopee-popup-form__prompt-message {
    display: flex;
    margin: 0 0 15px;
    color: #764;
    background: #fffefb;
    border: 1px solid rgba(255, 174, 4, .2);
    padding: 12px;
    border-radius: 2px
}

.shopee-popup-form__prompt-message-text {
    flex: 1
}

.shopee-popup-form__message {
    color: rgba(0, 0, 0, .54)
}

.shopee-popup-form__server-error {
    width: 100%;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff424f
}

.shopee-popup-form__subheader {
    display: flex;
    margin: .375rem 0 .5rem;
    color: rgba(0, 0, 0, .54)
}

.shopee-popup-form__subheader-text {
    text-transform: capitalize
}

.shopee-popup-form__gap {
    flex: 1
}

.shopee-popup-form__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    right: 0;
    height: 84px;
    text-transform: uppercase;
    background: linear-gradient(hsla(0, 0%, 100%, .9), #fff);
    align-items: center;
    padding: 22px 30px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px
}

.shopee-popup-form__footer .shopee-button-outline,
.shopee-popup-form__footer .shopee-popup-form__cancel,
.shopee-popup-form__footer>.shopee-button-solid {
    text-transform: uppercase;
    width: 140px;
    font-size: .875rem;
    font-weight: 400;
    padding: .75rem 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    outline: 0
}

.shopee-popup-form__inputs-row {
    display: flex
}

.shopee-popup-form--voice-otp {
    max-height: 260px
}

.shopee-popup-form--user-detail,
.shopee-popup-form--verify {
    height: 21.25rem
}

.shopee-popup-form--bank-account,
.shopee-popup-form--credit-card {
    height: 34.375rem
}

.shopee-popup-form--bank-account-id {
    height: 34.375rem;
    width: 640px
}

.shopee-popup-form__my-address-toggle {
    color: #ee4d2d;
    text-decoration: underline;
    cursor: pointer
}

.shopee-popup-form__my-address-toggle:first-letter {
    text-transform: uppercase
}

.shopee-popup-form--user-detail-tw {
    height: 34.375rem
}

.icon-prompt-bell {
    margin-right: 5px;
    width: 22px;
    height: 20px
}

.mm4Nol {
    background: rgba(0, 0, 0, .4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 600
}

.fbd08Z {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    width: 27.5rem;
    font-size: 1rem;
    padding: 2rem 1.5rem 1.5rem;
    background: #fff;
    border-radius: .1875rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .5);
    z-index: 601
}

.OvPwtn,
.uagH3W {
    width: 100%;
    word-wrap: "break-word"
}

.s8\+kGZ {
    margin-left: 1rem
}

.uagH3W {
    font-weight: 400;
    color: rgba(0, 0, 0, .65)
}

.OvPwtn,
.uagH3W {
    font-size: 1rem;
    line-height: 1.5rem
}

.OvPwtn {
    font-weight: 500
}

.SfVtNi {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.8125rem;
    width: 100%
}

.amEqwW {
    margin-left: .5rem
}

._8L1899,
.amEqwW {
    padding: .5rem 1rem
}

._8L1899 {
    border: .0625rem solid rgba(0, 0, 0, .26)
}

.o1ZFAc {
    width: 5.625rem;
    height: 5.625rem
}

.i9R0KR {
    bottom: 0
}

.i9R0KR,
.n5GtaG {
    position: -webkit-sticky;
    position: sticky
}

.n5GtaG {
    bottom: 12.5rem
}

@media screen and (max-width:1410px) {
    .n5GtaG {
        display: none
    }
}

.K691sR {
    right: -6.375rem;
    bottom: 0
}

._4GE1MW,
.K691sR {
    position: absolute
}

._4GE1MW {
    right: .75rem;
    bottom: 6.625rem
}

.AiQAFL {
    width: 38.5rem;
    height: 38.75rem
}

.AiQAFL .shopee-popup-form__main {
    margin-left: -.25rem;
    padding-left: .25rem
}

.AiQAFL .shopee-popup-form__header {
    height: unset;
    margin-bottom: 1.25rem;
    justify-content: space-between
}

.AiQAFL .shopee-popup-form__footer {
    height: 4rem;
    padding: 0 1.5rem
}

.AiQAFL .shopee-popup-form__footer button {
    min-width: 8.75rem;
    height: 2.5rem
}

.AiQAFL .shopee-popup-form__footer .cancel-btn {
    margin-right: .5rem;
    border: .0625rem solid rgba(0, 0, 0, .26)
}

.mcSUVg {
    padding: 1.5625rem;
    text-align: center
}

._5B8DXO {
    display: flex;
    flex-direction: column;
    height: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding-bottom: 4rem
}

.jDnDL1 {
    margin-bottom: .625rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, .65);
    display: flex;
    justify-content: space-between
}

.jDnDL1 span {
    color: #8e8e8e;
    font-size: .875rem
}

.jDnDL1.L2Z36v {
    color: rgba(0, 0, 0, .87)
}

.n\+yA6Y {
    display: flex;
    flex-direction: column
}

.ql5NCi {
    margin-bottom: 1.875rem
}

.Oh7Os0 {
    margin-left: -.25rem;
    padding-left: .25rem;
    overflow: auto;
    position: relative;
    padding-right: 1.25rem;
    margin-top: 1.25rem;
    width: 100%;
    height: 100%
}

.Oh7Os0.PYq1j2 {
    padding-bottom: 2.8125rem
}

.VGfPnr {
    margin-top: .3125rem;
    margin-bottom: .9375rem;
    padding: .625rem .9375rem;
    background: #fef7e7;
    color: rgba(0, 0, 0, .65);
    font-size: .875rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.c5m3Tg {
    background: #fff8e4;
    color: rgba(0, 0, 0, .87);
    display: flex;
    align-items: center
}

.qhxPKt {
    margin-right: .5rem
}

._5iIpDs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer
}

.\+al9fF {
    right: -1.5625rem !important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .14)
}

.\+al9fF:before {
    width: 1.125rem;
    height: 1.125rem;
    right: 1.375rem;
    top: -.375rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    box-shadow: 0 3px 10px rgba(0, 0, 0, .14)
}

.\+al9fF:after,
.\+al9fF:before {
    content: "";
    position: absolute;
    background: #fff
}

.\+al9fF:after {
    width: 2.375rem;
    height: 1.5rem;
    top: 0;
    right: .75rem
}

._6y3dGO {
    font-size: .875rem;
    color: rgba(0, 0, 0, .4)
}

.B1rhW7 {
    position: absolute;
    bottom: 100%;
    background: #fff;
    left: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, .09)
}

.vcHBnr {
    display: flex;
    align-items: center;
    height: 2.6875rem;
    margin-left: 1.5rem
}

.T2AksG {
    font-size: .875rem;
    line-height: 1.25rem;
    word-break: break-word;
    text-transform: none;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2
}

.ojH8m- {
    color: #ee4d2d
}

.logv2\+ {
    opacity: .5
}

._8BVf4d {
    text-align: center
}

.kharO6.HZoK4M .stardust-popup__dialog--wrapper {
    max-width: unset;
    width: unset
}

.kharO6.HZoK4M .stardust-popup__dialog {
    width: 7.5rem;
    height: 7.5rem;
    padding: 0;
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 .5625rem rgba(0, 0, 0, .12)
}

.kharO6.HZoK4M .stardust-popup__dialog--wrapper-top {
    padding: 0
}

.kharO6.HZoK4M .stardust-popup-buttons {
    display: none
}

.kharO6.HZoK4M .stardust-popup__overlay {
    background: none
}

.JTLJA4 .stardust-toast__text {
    word-break: normal
}

.stardust-popup {
    display: flex;
    align-items: center
}

.stardust-popup,
.stardust-popup__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.stardust-popup__overlay {
    background: rgba(0, 0, 0, .4)
}

.stardust-popup__dialog {
    background: #fff;
    border-radius: 4px;
    margin: auto 34px
}

.stardust-popup__dialog--wrapper {
    max-width: 375px;
    width: 100%;
    z-index: 2;
    margin: 0 auto
}

.stardust-popup__dialog--wrapper-top {
    padding: 20px 20px 0
}

.stardust-popup-title {
    font-size: 16px;
    color: rgba(0, 0, 0, .87);
    text-align: center
}

.stardust-popup-content,
.stardust-popup-title {
    font-family: -apple-system, Helvetica Neue, Helvetica, Roboto, Droid Sans, Arial, sans-serif;
    font-weight: 400
}

.stardust-popup-content {
    font-size: 14px;
    text-align: left;
    color: rgba(0, 0, 0, .54)
}

.stardust-popup-buttons {
    margin-top: 26px;
    display: flex;
    align-content: space-between
}

.stardust-popup-button {
    font-family: -apple-system, Helvetica Neue, Helvetica, Roboto, Droid Sans, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    height: 46px;
    line-height: 46px;
    color: #ee4d2d;
    border-top: 1px solid rgba(0, 0, 0, .09);
    flex: 1 100%;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center
}

.stardust-popup-button:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, .09)
}

/*# sourceMappingURL=https://sourcemap.webfe.shopee.io/pcmall-voucher/_/pcmall-voucherwalletmodalpc.2f70a640777869dafefc.legacy.css.map*/