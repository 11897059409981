.switchCustom{
    -webkit-appearance: none;
    appearance: none;
    width:3rem;
    height:1.5rem;
    background-color: #c6c6c6;
    border-radius: 40px;
    border:1px solid gray;
    outline: none;
    position: relative;
    transition: 0.3s all ease;
}
.switchCustom:checked{
    background-color: mediumaquamarine;
    border:1px solid mediumaquamarine;
}
.switchCustom::before{
    content: '';
    width: 1.4rem;
    height: 1.4rem;
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    top:-0.1px;
    left:0px;
    transition: 0.3s all ease;
}
.switchCustom:checked:before{
    left: 1.42rem;
}

.inputModal {
    width: 100%;
    padding: 1.2rem 0.7rem;
  
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .inputModal:focus {
  /* border-color: #2664ff; */
  border:2px solid #2664ff;
  box-shadow:0px 0px 1.5px #2664ff;
  }
  