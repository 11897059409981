.react-tabs__tab--selected{
    border-radius: 0 0 0 0 !important;
    background-color: #0075C4 !important;
    color:white !important;
  
 

    
}
.react-tabs__tab--selected p {
    color:white !important; 
}
.col-10{
    width: 100%;
    border: 0.7px solid #c4c4c4;
    border-radius: 0.2rem;
    position: relative;
    transition: 0.3s all ease;
}
.col-header,.col-banner,.col-blog,.col-product,.col-footer{
    transition: 0.3s all ease;
}
.col-header:hover,.col-banner:hover,.col-product:hover,.col-blog:hover,.col-footer:hover{
    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
}
.col-header img,.col-banner img,.col-product img,.col-blog img,.col-footer img{
    object-fit: contain;
    border-radius: 0px;
    width: 100%;
    height: auto;
   
}
.col-header >span,.col-banner >span,.col-product >span,.col-blog >span,.col-footer >span{
   
    position: absolute;
    top: -1px;
    left: -1px;
    background: #c7c7c7;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    transition: 0.3s all ease;
 

    opacity: 1;
    color: white;
}
.col-header:hover span,.col-banner:hover span,.col-product:hover span,.col-blog:hover span,.col-footer:hover span{
background:#0075c4;
}
.col-header .linear,.col-banner .linear,.col-blog .linear,.col-product .linear,.col-footer .linear{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease;
    
}
.col-header:hover .linear,.col-banner:hover .linear,.col-blog:hover .linear,.col-product:hover .linear,.col-footer:hover .linear{
    background-color: rgba(0,0,0,0.05);
}
.col-header .btn-header,.col-banner .btn-banner,.col-blog .btn-blog,.col-product .btn-product,.col-footer .btn-footer{

    opacity: 0;
    transition: 0.3s all ease;
    background:#0075c4 !important;
    border-radius: 50%;
    width:3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.col-header:hover .btn-header,.col-banner:hover .btn-banner,.col-blog:hover .btn-blog,.col-product:hover .btn-product,.col-footer:hover .btn-footer{
    opacity: 1;
    }
    .overview  .box-body{
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
        margin:10px auto;
    }
  .overview .col-10-wrapper {
    flex: 0 0 84.33333% !important;
    max-width: 84.33333% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}
    .overview  .box-body  button.slick-arrow.slick-prev {
        left: 0px;
    }
    .overview  .box-body  .slick-dots {
        position: absolute;
        bottom: -11px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
    }
       .overview  .box-body  button.slick-arrow.slick-prev,   .overview  .box-body  button.slick-arrow.slick-next {
        background-color: rgba(0,0,0,0.3) !important;
        padding: 0.5em;
        width: 30px;
        height: 40px;
        z-index: 3;
        border-radius: 4px;
        position: absolute;
        top:50%;

    }
       .overview  .box-body  button.slick-arrow.slick-next{
        right:0%;   
    }
    .overview .box-body .col-header, .overview .box-body .col-banner, .overview .box-body .col-product, .overview .box-body .col-blog, .overview .box-body .col-footer{
        display: block;
  position: relative;
  margin: auto;
 
  height: 350px;
  border:1px solid rgba(0,0,0,0.3);
  padding:0px !important;

    }   
    .overview .box-body .col-header img, .overview .box-body .col-banner img, .overview .box-body .col-product img, .overview .box-body .col-blog img, .overview .box-body .col-footer img{
      
        width:100%;
        height: 100%;
        object-fit: center;
   
    }