.barcode-wrap {
    display: flex;
    flex-flow: column;
    font-weight: 500;
    align-items: center;
    padding: 20px 21%;
    border: 1px solid #c4cdd5;
    border-radius: 5px;    
}

.image-option-print {
    max-width: 100%;
    height: 80px;
    vertical-align: middle;
}

.print-feature-container {
    display : flex
}
.print-feature-container .name {
    margin: auto 0;
    margin-left: 20px;
}